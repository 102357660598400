@charset "utf-8";


$primary-color: #047ffb;

$sans-serif: "Verdana", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
  "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;

$sans-serif-with-oswald: "Oswald", $sans-serif;

$header-font-family: $sans-serif-with-oswald;

.figure-column {
  flex-direction: column;
}

.img-block  {
  display: block;
  max-width: 700px;
}

.gallery-anchor  {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.img-title  {
  display: block;
  margin: 1em 0 2em;
  color: #000;
}

.page__content p, li, dl {
  font-size: 15px !important;
}

.site-title {
  font-family: $header-font-family;
}

.masthead__menu-item {
  font-family: $header-font-family;
}

.site-subtitle {
  font-family: $sans-serif;
  font-size: 11px !important;
}

@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials
